// Custom Properties in activite
:root {
    // Product
    --background-product-buy-button: var(--theme-tertiary);

    // Theme
    --theme-on-default-highlight: var(--theme-primary);

    // PLP
    --plp-headline-color: var(--theme-cta-font-color);

    // Footer
    --footer-font-color: var(--theme-cta-font-color);

    // Button
    --bg-button-primary: var(--theme-primary);

    // PDP
    --pdp-background-buy-button: var(--theme-tertiary);
    --pdp-color-buy-button: var(--theme-dark);

    // NavBar Quicklink Button
    --quicklink-button-color: var(--theme-tertiary);

    // Empty Cart Button
    --empty-cart-link: var(--theme-cta-font-color);

    // Navigation
    --main-navigation-grid-column-width-wide: 175px 35% auto 160px;
    --free-delivery-highlighted: var(--color-red);
    --main-logo-wrapper-max-width-desktop: 155px;
    --main-logo-wrapper-min-height-mobile: 75px;
    --main-logo-max-height-mobile: 80px;
}
